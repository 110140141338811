import { Container } from 'Container';
import { Image, type ImageProps } from 'Image';
import { useEffect, useState } from 'react';
import { Typography } from 'Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'FrontRoyalConfig';
import { useOfflineModeManager, useTargetBrandConfig } from 'FrontRoyalAngular';
import { InstitutionId } from 'Institutions';
import clsx from 'clsx';

import logoRedQuantic from 'vectors/footer/logos-quantic-logo.svg';
import logoBlueValar from 'vectors/footer/logos-valar-logo.svg';
import logoRedSmartly from 'vectors/footer/logos-smartly-logo.svg';
import logoMiyaMiya from 'images/footer/miya-miya-logo.png';
import logoMiyaMiyaRetina from 'images/footer/miya-miya-logo@2x.png';
import availableAppStore from 'vectors/footer/available-app-store.svg';
import availableGooglePlay from 'vectors/footer/available-google-play.svg';

const logoQuanticImage: Omit<ImageProps, 'alt'> = {
    mobileImage: {
        width: 132,
        height: 32,
        images: {
            default: logoRedQuantic,
        },
    },
};
const logoValarImage: Omit<ImageProps, 'alt'> = {
    mobileImage: {
        width: 119,
        height: 32,
        images: {
            default: logoBlueValar,
        },
    },
};
const logoSmartlyImage: Omit<ImageProps, 'alt'> = {
    mobileImage: {
        width: 137,
        height: 32,
        images: {
            default: logoRedSmartly,
        },
    },
};
const logoMiyaMiyaImage: Omit<ImageProps, 'alt'> = {
    mobileImage: {
        width: 140,
        height: 32,
        images: {
            default: logoMiyaMiya,
            retina: logoMiyaMiyaRetina,
        },
    },
};
const appStoreImage: Omit<ImageProps, 'alt'> = {
    mobileImage: {
        width: 94,
        height: 24,
        images: {
            default: availableAppStore,
        },
    },
};
const googlePlayImage: Omit<ImageProps, 'alt'> = {
    mobileImage: {
        width: 94,
        height: 24,
        images: {
            default: availableGooglePlay,
        },
    },
};

const brandLogoMap: Record<InstitutionId, Omit<ImageProps, 'alt'>> = {
    [InstitutionId.quantic]: logoQuanticImage,
    [InstitutionId.valar]: logoValarImage,
    [InstitutionId.smartly]: logoSmartlyImage,
    [InstitutionId.miya_miya]: logoMiyaMiyaImage,
};

export function Footer({ className }: { className?: string }) {
    const { t } = useTranslation('back_royal');
    const config = useConfig();
    const targetBrandConfig = useTargetBrandConfig();
    const showCookiePreferences = config?.gdprAppliesToUser() || false;
    const offlineModeManager = useOfflineModeManager();

    // Hybrid app has no footer.  When offline, none of the
    // links will work, so we hide the whole thing.
    const [showFooter, setShowFooter] = useState(window.CORDOVA ? false : !offlineModeManager.inOfflineMode);
    useEffect(() => {
        setShowFooter(window.CORDOVA ? false : !offlineModeManager.inOfflineMode);
    }, [offlineModeManager]);

    if (!showFooter) {
        // spacer for CORDOVA app since the tab bar overlaps
        return <div className="mb-[55px] sm:mb-0" />;
    }

    return (
        <footer className={clsx('mb-[55px] bg-white sm:mb-0', className)}>
            <Container className="px-2.5 xs:px-[15px]">
                <div className="flex flex-col items-center gap-6 pb-6 pt-10 md:flex-row md:justify-between">
                    <Link to="/" title={t('resources.resources.homepage') as string}>
                        <Image
                            {...brandLogoMap[targetBrandConfig.institutionId]}
                            className="h-8 flex-shrink-0"
                            alt={targetBrandConfig.brandNameShort}
                        />
                    </Link>
                    <div className="flex gap-10">
                        <Link to="/smartly-ios" target="_blank">
                            <Image
                                {...appStoreImage}
                                className="flex-shrink-0 brightness-100 transition hover:brightness-75"
                                alt={t('resources.resources.available_app_store')}
                            />
                        </Link>
                        <Link to="/smartly-android" target="_blank">
                            <Image
                                {...googlePlayImage}
                                className="flex-shrink-0 brightness-100 transition hover:brightness-75"
                                alt={t('resources.resources.available_google_play')}
                            />
                        </Link>
                    </div>
                </div>
                <hr className="border-beige-light" />
                <div className="pb-10 pt-6 text-center md:flex md:items-center md:justify-center md:pb-8">
                    <Typography variant="link" className="text-light-gray">
                        {t('resources.resources.copyright')}
                        <br className="md:hidden" />
                        <span className="hidden md:inline">&nbsp;</span>
                        <Link
                            to="/privacy"
                            target="_blank"
                            className="font-bold transition hover:text-gray hover:no-underline"
                        >
                            {t('resources.resources.privacy_policy')}
                        </Link>
                        &nbsp;|&nbsp;
                        <Link
                            to="/terms"
                            target="_blank"
                            className="font-bold transition hover:text-gray hover:no-underline"
                        >
                            {t('resources.resources.terms_and_conditions')}
                        </Link>
                        {showCookiePreferences ? (
                            <>
                                &nbsp;|&nbsp;
                                <Link
                                    to="/#"
                                    className="font-bold transition hover:text-gray hover:no-underline"
                                    onClick={e => {
                                        e.preventDefault();
                                        // eslint-disable-next-line new-cap
                                        window.Optanon?.ToggleInfoDisplay();
                                    }}
                                >
                                    {t('resources.resources.cookies')}
                                </Link>
                            </>
                        ) : null}
                    </Typography>
                </div>
            </Container>
        </footer>
    );
}
