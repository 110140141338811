import { Icon } from 'FrontRoyalIcon';
import { Link } from 'react-router-dom';
import { Typography } from 'Typography';
import { Image, type ImageProps } from 'Image';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTargetBrandConfig } from 'FrontRoyalAngular';
import { InstitutionId } from 'Institutions';

import quanticImageSrc from 'vectors/resources/resources-article-image-quantic.svg';
import valarImageSrc from 'vectors/resources/resources-article-image-valar.svg';

type ArticleCardProps = {
    title: string;
    description: string;
    url: string;
    linkText?: string;
    className?: string;
};

const quanticImage: ImageProps = {
    mobileImage: {
        width: 40,
        height: 40,
        images: {
            default: quanticImageSrc,
        },
    },
    desktopImage: {
        width: 50,
        height: 50,
        images: {
            default: quanticImageSrc,
        },
    },
    alt: '',
};
const valarImage: ImageProps = {
    mobileImage: {
        width: 40,
        height: 40,
        images: {
            default: valarImageSrc,
        },
    },
    desktopImage: {
        width: 50,
        height: 50,
        images: {
            default: valarImageSrc,
        },
    },
    alt: '',
};

export function ArticleCard({ title, description, url, linkText, className }: ArticleCardProps) {
    const { t } = useTranslation('back_royal');
    const targetBrandConfig = useTargetBrandConfig();
    const isValar = targetBrandConfig.institutionId === InstitutionId.valar;

    return (
        <Link
            to={url}
            className={clsx('group block rounded bg-white p-2.5 shadow transition hover:shadow-lg md:p-3.5', className)}
            target="_blank"
        >
            <div className="flex items-center gap-[5px] md:gap-2.5">
                <div className="w-10 shrink-0 self-start md:w-[50px]">
                    <Image {...(isValar ? valarImage : quanticImage)} />
                </div>
                <div>
                    <Typography
                        variant="header3"
                        className="uppercase text-coral-dark transition group-hover:text-coral valar:text-blue-dark valar:group-hover:text-blue"
                    >
                        {title}
                    </Typography>
                    <div className="hidden md:block">
                        <Typography variant="body3" className="text-beige-beyond-beyond-dark md:mt-2 md:line-clamp-2">
                            {description}
                        </Typography>
                    </div>
                </div>
            </div>
            <Typography variant="body3" className="mt-[5px] line-clamp-2 text-beige-beyond-beyond-dark md:hidden">
                {description}
            </Typography>
            <div className="mt-2 flex justify-end">
                <span className="inline-flex gap-[0.35rem] text-coral transition group-hover:text-coral-dark valar:text-blue valar:group-hover:text-blue-dark">
                    <Typography variant="link" className="font-medium">
                        {linkText || t('resources.resources.read_more')}
                    </Typography>
                    <Icon iconName="RightArrow16" className="rtl:rotate-180" />
                </span>
            </div>
        </Link>
    );
}
