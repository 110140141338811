import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequiredCurrentUser } from 'FrontRoyalAngular';
import { SCHEDULE_ADMISSIONS_CHAT_LINK } from 'AdmissionsHelp/constants';

import headerImageMobile from 'images/resources/resources-admissions-header.png';
import headerImageMobileRetina from 'images/resources/resources-admissions-header@2x.png';
import rocketImageMobile from 'images/resources/resources-admissions-rocket.png';
import rocketImageMobileRetina from 'images/resources/resources-admissions-rocket@2x.png';
import { getCurrentlyPreventedFromSubmittingApplication } from 'Users';
import { ROUTES } from '../ResourcesRoutes';
import { CategoryResultsSection } from '../CategoryResultsSection/CategoryResultsSection';
import { useSearchResults } from '../useSearchResults';
import { type CategoryDescriptionSectionProps } from '../CategoryDescriptionSection/CategoryDescriptionSection.types';
import { CategoryDescriptionSection } from '../CategoryDescriptionSection';

export function Admissions() {
    const { t } = useTranslation('back_royal');
    const currentUser = useRequiredCurrentUser();

    const searchResults = useSearchResults({
        categorySlug: ROUTES.RESOURCES.$.ADMISSIONS.relativePath,
        searchAllSite: false,
    });

    const showPostApplicationContent = getCurrentlyPreventedFromSubmittingApplication(currentUser);

    const categoryDescription: CategoryDescriptionSectionProps = useMemo(
        () => ({
            header: {
                title: t('resources.resources.admissions_title'),
                image: {
                    mobileImage: {
                        width: 40,
                        height: 40,
                        images: {
                            default: headerImageMobile,
                            retina: headerImageMobileRetina,
                        },
                    },
                    alt: '',
                },
            },
            content: [
                {
                    title: t('resources.resources.admissions_content_title'),
                    description: t(
                        `resources.resources.admissions_content_${
                            showPostApplicationContent ? 'post' : 'pre'
                        }_application_description`,
                        {
                            scheduleAdmissionsChatLink: SCHEDULE_ADMISSIONS_CHAT_LINK,
                        },
                    ),
                    descriptionAsChild: true,
                },
            ],
            image: {
                mobileImage: {
                    width: 374,
                    height: 303,
                    images: {
                        default: rocketImageMobile,
                        retina: rocketImageMobileRetina,
                    },
                },
                alt: 'Rocket illustration',
            },
        }),
        [t, showPostApplicationContent],
    );

    return (
        <div className="bg-white">
            <CategoryDescriptionSection {...categoryDescription} />
            <CategoryResultsSection {...searchResults} />
        </div>
    );
}
