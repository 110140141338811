import { type ReactNode, type ComponentProps } from 'react';
import { ArticleCard } from 'Resources/ArticleCard';
import { Typography } from 'Typography';

type ArticleGalleryProps = {
    title: string;
    subTitle?: string;
    items: ComponentProps<typeof ArticleCard>[];
    children?: ReactNode;
};

export function ArticleGallery({ title, subTitle, items, children }: ArticleGalleryProps) {
    return (
        <div className="mx-auto max-w-[555px] md:max-w-full">
            <div>
                <Typography variant="header2" className="uppercase text-beige-beyond-beyond-dark">
                    {title}
                </Typography>
                {subTitle ? (
                    <Typography variant="body3" className="mt-1 uppercase text-beige-beyond-beyond-dark md:mt-2.5">
                        {subTitle}
                    </Typography>
                ) : null}
            </div>

            {items.length > 0 ? (
                <div className="mt-7.5 grid grid-cols-1 gap-7.5 md:grid-cols-2">
                    {items.map(item => (
                        <ArticleCard key={item.title} {...item} className="md:flex md:flex-col md:justify-between" />
                    ))}
                </div>
            ) : null}

            {children}
        </div>
    );
}
