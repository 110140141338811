import { Fragment, useEffect, useId, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { Form, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { Icon } from 'FrontRoyalIcon';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { useAngularContext } from 'AngularContext';
import { typedBoolean } from 'FrontRoyalAngular/misc';
import { type Cohort } from 'Cohorts';
import { getProgramConfigValue } from 'Program';
import { useAdmissionsTab } from '../useAdmissionsTab';
import { ROUTES } from '../ResourcesRoutes';

const menuItemStyles = cva(
    [
        'text-sm pt-2.5 pb-[9px] px-3 leading-[17px] tracking-[0.1px] rounded whitespace-nowrap transition ease-in-out',
        'md:text-md md:pt-2 md:pb-[7px] md:leading-[21px] md:tracking-normal',
        // Language-specific styles
        'lang-group-am-es-it:text-xs lang-group-am-es-it:md:text-sm lang-group-am-es-it:min-[768px]:max-[850px]:px-1.5',
    ],
    {
        variants: {
            variant: {
                default: 'text-white bg-transparent hover:bg-white/20',
                active: 'text-beige-beyond-beyond-dark bg-white shadow',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

type AnimationStatus = 'paused' | 'entering' | 'leaving';

// eslint-disable-next-line max-lines-per-function
export function CategoryMenu({ cohort }: { cohort: Cohort }) {
    const { t } = useTranslation('back_royal');
    const injector = useAngularContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [{ query }] = useTypedSearchParams(ROUTES.RESOURCES.SEARCH);
    const formId = useId();
    const [searchOpen, setSearchOpen] = useState(location.pathname === ROUTES.RESOURCES.SEARCH.path);
    const [animationStatus, setAnimationStatus] = useState<AnimationStatus>('paused');
    const inputRef = useRef<HTMLInputElement>(null);
    const scrollHelper = injector.get<{ scrollToTop(smooth?: boolean): void }>('scrollHelper');
    const isExecEd = getProgramConfigValue(cohort.programType, 'isExecEd');

    const { showAdmissionsTab } = useAdmissionsTab();

    const categories = useMemo(
        () =>
            [
                showAdmissionsTab
                    ? { title: t('resources.resources.admissions'), href: ROUTES.RESOURCES.ADMISSIONS.path }
                    : null,
                {
                    title: t('resources.resources.academic_and_program_support'),
                    href: ROUTES.RESOURCES.ACADEMIC_AND_PROGRAM_SUPPORT.path,
                },
                { title: t('resources.resources.career_services'), href: ROUTES.RESOURCES.CAREER_SERVICES.path },
                !isExecEd
                    ? { title: t('resources.resources.library_services'), href: ROUTES.RESOURCES.LIBRARY_SERVICES.path }
                    : null,
                { title: t('resources.resources.student_events'), href: ROUTES.RESOURCES.STUDENT_EVENTS.path },
            ].filter(typedBoolean),
        [t, showAdmissionsTab, isExecEd],
    );

    const emptyCategory = useMemo(
        () => ({
            title: t('resources.resources.select_a_category'),
            href: '',
        }),
        [t],
    );
    const [category, setCategory] = useState(categories.find(c => c.href === location.pathname) ?? emptyCategory);

    useEffect(() => {
        if (location.state?.openSearch === true) {
            if (searchOpen) {
                scrollHelper.scrollToTop(true);
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 500);
            } else {
                setSearchOpen(true);
                scrollHelper.scrollToTop(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state]);

    useEffect(() => {
        if (location.pathname !== ROUTES.RESOURCES.SEARCH.path) {
            setSearchOpen(false);
            setCategory(categories.find(c => c.href === location.pathname) ?? emptyCategory);
        } else if (location.pathname === ROUTES.RESOURCES.SEARCH.path) {
            setCategory(emptyCategory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <div className="relative bg-coral valar:bg-blue">
            <Transition
                show={!searchOpen}
                unmount={false}
                enter="transition-opacity duration-200 delay-700 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className={clsx(
                        'absolute top-0 min-h-[56px] w-[calc(100%-52px)] py-2.5 sm:hidden ltr:left-0 ltr:pl-5 ltr:pr-3 rtl:right-0 rtl:pl-3 rtl:pr-5',
                        searchOpen ? 'z-10' : 'z-20',
                    )}
                >
                    <div className="relative flex w-full">
                        <Listbox
                            value={category}
                            onChange={value => {
                                setCategory(value);
                                navigate(value.href);
                            }}
                            disabled={searchOpen || animationStatus !== 'paused'}
                            by="href"
                        >
                            {({ open }) => (
                                <>
                                    <Listbox.Label className="sr-only">
                                        {t('resources.resources.category')}
                                    </Listbox.Label>
                                    <div className="relative -mx-1.5 w-[calc(100%+12px)]">
                                        <Listbox.Button className="relative w-full cursor-default rounded bg-transparent pb-[7px] pl-3 pr-8 pt-2 text-left text-md leading-[21px] text-white transition ease-in-out hover:bg-white/20">
                                            <span className="block truncate">{parseHtml(category.title)}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <Icon iconName="ChevronDown16" className="h-4 w-4" aria-hidden="true" />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1.5 shadow ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {categories.map(item => (
                                                    <Listbox.Option
                                                        key={item.href}
                                                        className="relative cursor-default select-none px-3 py-2 text-beige-beyond-beyond-dark ui-selected:text-coral ui-active:bg-beige ui-active:text-coral valar:ui-selected:text-blue valar:ui-active:text-blue"
                                                        value={item}
                                                    >
                                                        <span className="block truncate">{parseHtml(item.title)}</span>
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                </div>
            </Transition>
            <div
                className={clsx(
                    'relative mx-auto max-w-[1020px] overflow-hidden px-5 py-2.5 sm:px-8',
                    searchOpen ? 'z-20' : 'z-10',
                )}
            >
                <nav className="relative flex h-9 items-center justify-between">
                    <div className="hidden h-9 w-full sm:flex ltr:pr-10 rtl:pl-10">
                        <Transition
                            show={!searchOpen}
                            className={clsx(
                                'hide-scrollbar flex grow justify-center overflow-x-auto overscroll-none',
                                'gap-x-2',
                                'lang-group-am-es-it:min-[768px]:max-[850px]:gap-x-0.5', // Help prevent overflow
                            )}
                            enter="transition-opacity duration-200 delay-700 ease-out"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150 ease-in"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {categories.map(item => (
                                <NavLink
                                    key={item.title}
                                    to={item.href}
                                    className={({ isActive }) =>
                                        menuItemStyles({ variant: isActive ? 'active' : 'default' })
                                    }
                                >
                                    {parseHtml(item.title)}
                                </NavLink>
                            ))}
                        </Transition>
                    </div>
                    <div
                        className={clsx(
                            'absolute left-0 top-0 flex h-full w-full transform items-center transition-all duration-700 ease-in-out',
                            searchOpen
                                ? 'translate-x-0'
                                : 'delay-150 ltr:translate-x-[calc(100%-30px)] sm:ltr:translate-x-[calc(100%-24px)] md:ltr:translate-x-[calc(100%-36px)] rtl:-translate-x-[calc(100%-30px)] sm:rtl:-translate-x-[calc(100%-24px)] md:rtl:-translate-x-[calc(100%-36px)]',
                        )}
                    >
                        <div
                            className={clsx(
                                'mx-auto flex w-full items-center transition-all duration-700 ease-in-out',
                                searchOpen ? 'max-w-[788px] px-1.5 sm:px-0' : 'max-w-full px-0 delay-150',
                            )}
                        >
                            <div className="flex w-[calc(100%+16px)] items-center gap-0.5 sm:gap-2.5 ltr:-ml-1.5 ltr:-mr-2.5 rtl:-ml-2.5 rtl:-mr-1.5">
                                <button
                                    type={searchOpen && animationStatus === 'paused' ? 'submit' : 'button'}
                                    form={searchOpen && animationStatus === 'paused' ? formId : undefined}
                                    onClick={() => {
                                        if (!searchOpen && animationStatus === 'paused') {
                                            setSearchOpen(true);
                                        } else if (
                                            searchOpen &&
                                            animationStatus === 'paused' &&
                                            !inputRef.current?.value
                                        ) {
                                            inputRef.current?.focus();
                                        }
                                    }}
                                    className="flex rounded border-none bg-transparent p-1.5 text-white transition ease-in-out hover:bg-white/20"
                                    disabled={animationStatus !== 'paused'}
                                >
                                    <span className="sr-only">
                                        {searchOpen
                                            ? t('resources.resources.search')
                                            : t('resources.resources.open_search')}
                                    </span>
                                    <Icon iconName="Search24" className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <Transition
                                    show={searchOpen}
                                    unmount={false}
                                    className="flex-1"
                                    enter="transition-opacity duration-200 delay-200 ease-out"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-150 delay-200 ease-in"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    beforeEnter={() => setAnimationStatus('entering')}
                                    afterEnter={() => {
                                        setAnimationStatus('paused');
                                        inputRef.current?.focus();
                                    }}
                                    beforeLeave={() => setAnimationStatus('leaving')}
                                    afterLeave={() => {
                                        setAnimationStatus('paused');
                                        if (inputRef.current?.value) {
                                            inputRef.current.value = '';
                                        }
                                        if (location.state?.openSearch === true) {
                                            navigate(
                                                { pathname: location.pathname, search: location.search },
                                                { replace: true, state: {} },
                                            );
                                        }
                                    }}
                                >
                                    <Form
                                        id={formId}
                                        method="get"
                                        action={ROUTES.RESOURCES.SEARCH.path}
                                        onSubmit={e => {
                                            if (!inputRef.current?.value) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            name="query"
                                            placeholder={t('resources.resources.search_for_articles') as string}
                                            className="w-full border-none bg-coral py-2 text-md leading-5 text-white caret-white placeholder:text-white/80 focus:outline-none valar:bg-blue"
                                            maxLength={64}
                                            defaultValue={query}
                                        />
                                    </Form>
                                </Transition>
                                <Transition
                                    show={searchOpen}
                                    unmount={false}
                                    enter="transition-opacity duration-200 delay-700 ease-out"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-150 ease-in"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setSearchOpen(false);
                                        }}
                                        className="flex rounded border-none bg-transparent p-2.5 text-white transition ease-in-out hover:bg-white/20"
                                        disabled={animationStatus !== 'paused'}
                                    >
                                        <span className="sr-only">{t('resources.resources.close_search')}</span>
                                        <Icon iconName="Close16" className="h-4 w-4" aria-hidden="true" />
                                    </button>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}
