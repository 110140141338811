import { type ImgHTMLAttributes } from 'react';
import clsx from 'clsx';
import { useMedia } from 'react-use';
import isChromatic from 'chromatic';
import { mediaQuery } from 'FrontRoyalAngular/media-query';

import { type ImageProps } from './Image.types';

function getResponsiveProps(
    image: ImageProps['mobileImage'],
): Pick<ImgHTMLAttributes<HTMLImageElement>, 'srcSet' | 'sizes'> {
    return {
        srcSet: image.images.retina
            ? `${image.images.default} ${image.width}w, ${image.images.retina} ${image.width * 2}w`
            : `${image.images.default} ${image.width}w`,
        sizes: `(min-width: ${image.width}px) ${image.width}px, 100vw`,
    };
}

export function Image({
    mobileImage,
    desktopImage,
    desktopMedia = mediaQuery.upMd,
    alt,
    className,
    imgClassName,
    ...props
}: ImageProps) {
    const parsedMedia = desktopMedia.startsWith('@media ') ? desktopMedia.replace('@media ', '') : desktopMedia;
    const isDesktop = useMedia(parsedMedia);

    const baseImage = (
        <img
            decoding="async"
            loading={isChromatic() ? 'eager' : 'lazy'}
            {...props}
            {...getResponsiveProps(mobileImage)}
            src={mobileImage.images.default}
            width={mobileImage.width}
            className={clsx('w-full', imgClassName)}
            alt={alt}
            style={{
                maxWidth: `${desktopImage && isDesktop ? desktopImage.width : mobileImage.width}px`,
                aspectRatio:
                    desktopImage && isDesktop
                        ? `${desktopImage.width}/${desktopImage.height}`
                        : `${mobileImage.width}/${mobileImage.height}`,
            }}
        />
    );

    if (desktopImage) {
        return (
            <picture className={clsx('block', className)}>
                <source {...getResponsiveProps(desktopImage)} width={desktopImage.width} media={parsedMedia} />
                {baseImage}
            </picture>
        );
    }

    return <div className={className}>{baseImage}</div>;
}
