import clsx from 'clsx';

type SlopeBackgroundProps = {
    className?: string;
};

export function SlopeBackground({ className }: SlopeBackgroundProps) {
    return (
        <div className={clsx('absolute -left-full top-0 h-full w-[200%] bg-beige', className)}>
            <div className="absolute left-0 top-0 h-64 w-full origin-top-right -rotate-[5.5deg] -scale-y-100 transform bg-white md:-rotate-[3.1deg] [@media(min-width:1900px)]:-rotate-2" />
        </div>
    );
}
