import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequiredCurrentUser } from 'FrontRoyalAngular';
import headerImageMobile from 'images/resources/career-services-illustration.png';
import headerImageMobileRetina from 'images/resources/career-services-illustration@2x.png';
import groupImageMobile from 'images/resources/career-services-group.png';
import groupImageMobileRetina from 'images/resources/career-services-group@2x.png';
import { getCohort } from 'Users';
import { getProgramConfigValue } from 'Program';
import { ROUTES } from '../ResourcesRoutes';
import { CategoryResultsSection } from '../CategoryResultsSection/CategoryResultsSection';
import { useSearchResults } from '../useSearchResults';
import { type CategoryDescriptionSectionProps } from '../CategoryDescriptionSection/CategoryDescriptionSection.types';
import { CategoryDescriptionSection } from '../CategoryDescriptionSection';

export function CareerServices() {
    const { t } = useTranslation('back_royal');
    const currentUser = useRequiredCurrentUser();
    // We can safely typecast these variables because routing to this page is only
    // possible if you're a logged-in user with a cohort.
    const cohort = getCohort(currentUser)!;
    const isExecEd = getProgramConfigValue(cohort.programType, 'isExecEd');

    const searchResults = useSearchResults({
        categorySlug: ROUTES.RESOURCES.$.CAREER_SERVICES.relativePath,
        searchAllSite: false,
    });

    const categoryDescription: CategoryDescriptionSectionProps = useMemo(
        () => ({
            header: {
                title: t('resources.resources.career_services_title'),
                image: {
                    mobileImage: {
                        width: 40,
                        height: 40,
                        images: {
                            default: headerImageMobile,
                            retina: headerImageMobileRetina,
                        },
                    },
                    alt: '',
                },
            },
            content: [
                {
                    title: t('resources.resources.career_services_content_title'),
                    description: t(
                        `resources.resources.career_services_content_description${isExecEd ? '_alternate' : ''}`,
                    ),
                },
            ],
            image: {
                mobileImage: {
                    width: 396,
                    height: 285,
                    images: {
                        default: groupImageMobile,
                        retina: groupImageMobileRetina,
                    },
                },
                alt: 'Group illustration',
            },
        }),
        [t, isExecEd],
    );

    return (
        <div className="bg-white">
            <CategoryDescriptionSection {...categoryDescription} />
            <CategoryResultsSection {...searchResults} />
        </div>
    );
}
