import { useCallback, useState } from 'react';
import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { useTranslation } from 'react-i18next';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { Typography } from 'Typography';
import { DismissableMessage } from 'DismissableMessage/DismissableMessage';

export function WelcomeMessage() {
    const { t } = useTranslation('back_royal');
    // FIXME: swap to useCurrentUser hook, will need to create rtk user api to be able to update hasSeenResourcesTab
    const currentUser = useCurrentUserIguanaObject();
    const hasSeenResourcesTab = currentUser?.has_seen_resources_tab;
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(!hasSeenResourcesTab);

    const handleAfterLeave = useCallback(() => {
        if (!currentUser) return;
        currentUser.has_seen_resources_tab = true;
        if (!currentUser.ghostMode) currentUser.save();
    }, [currentUser]);

    if (hasSeenResourcesTab) return null;

    return (
        <div className="absolute right-0 top-0 z-50 px-3.5 py-8 sm:px-5 sm:py-5">
            <DismissableMessage
                show={showWelcomeMessage}
                onDismiss={() => {
                    setShowWelcomeMessage(false);
                }}
                onAfterLeave={handleAfterLeave}
            >
                <Typography variant="body2">{parseHtml(t('resources.resources.welcome_message'))}</Typography>
            </DismissableMessage>
        </div>
    );
}
