import { Typography } from 'Typography';
import { Image } from 'Image';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { useTranslation } from 'react-i18next';
import { Container } from 'Container';
import { AnchorLink } from 'AnchorLink';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { validTopLevelReactRoutes } from 'FrontRoyalRouter';
import { type CategoryDescriptionSectionProps } from './CategoryDescriptionSection.types';

export function CategoryDescriptionSection({ header, content, image }: CategoryDescriptionSectionProps) {
    const { t } = useTranslation('back_royal');
    const { loadRoute } = useNavigationHelper();

    return (
        <section className="py-7.5 md:pb-10 md:pt-13.5">
            <Container>
                <div className="mx-auto max-w-[555px] space-y-8 md:max-w-full md:space-y-13.5">
                    <header className="space-y-1 text-center">
                        <Typography variant="smallText" className="text-blue-grey/80">
                            {header.label || t('resources.resources.in_category')}
                        </Typography>
                        <div className="flex flex-col items-center gap-1 md:flex-row md:justify-center md:gap-4">
                            <div className="aspect-square w-10">
                                <Image {...header.image} imgClassName="mx-auto md:mx-0" />
                            </div>
                            <Typography variant="header1" className="text-grey-darker">
                                {header.title}
                            </Typography>
                        </div>
                    </header>
                    <div className="md:grid md:grid-cols-12 md:gap-7.5">
                        <div className="space-y-7.5 md:col-span-6">
                            {content.map(item => (
                                <div key={item.title} className="space-y-2.5">
                                    <Typography variant="header2" className="text-grey-darker">
                                        {item.title}
                                    </Typography>
                                    {item.description && (
                                        <Typography
                                            variant="body2"
                                            className="text-beige-beyond-beyond-dark"
                                            asChild={item.descriptionAsChild}
                                        >
                                            {parseHtml(item.description, {
                                                onLinkClick: (event, href) => {
                                                    const isRelative = href?.startsWith('/');
                                                    const basePath = href?.split('/')[1];
                                                    if (isRelative && !validTopLevelReactRoutes.includes(basePath)) {
                                                        event.preventDefault();
                                                        loadRoute(href!);
                                                    }
                                                    return null;
                                                },
                                            })}
                                        </Typography>
                                    )}

                                    {!!item.links?.length && (
                                        <ul className="flex flex-col gap-4">
                                            {item.links.map(link => (
                                                <li key={link.label} className="flex">
                                                    <AnchorLink
                                                        to={link.href}
                                                        withArrow
                                                        color="brand"
                                                        onClick={link.onClick}
                                                    >
                                                        <Typography variant="link" className="font-medium">
                                                            {link.label}
                                                        </Typography>
                                                    </AnchorLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    {item.subContent?.map(subItem => (
                                        <div key={subItem.title} className="mt-2.5">
                                            <Typography
                                                variant="body2"
                                                className="font-bold uppercase tracking-wider text-grey-darkish"
                                            >
                                                {subItem.title}
                                            </Typography>
                                            {subItem.description && (
                                                <Typography variant="body2" className="text-beige-beyond-beyond-dark">
                                                    {parseHtml(subItem.description)}
                                                </Typography>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="hidden md:col-span-6 md:block lg:col-span-5 lg:col-start-8">
                            <div className="flex aspect-[458/307] items-center justify-center">
                                <Image {...image} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
