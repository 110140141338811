import { AnchorLink } from 'AnchorLink';
import { Container } from 'Container';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Typography';
import { ArticleGallery } from '../ArticleGallery';
import { type getArticleCardsDetails } from '../useSearchResults';
import { SlopeBackground } from '../SlopeBackground';

type CategoryResultsSectionProps = {
    status: 'loading' | 'success' | 'error';
    articlesData: ReturnType<typeof getArticleCardsDetails> | null;
};

export function CategoryResultsSection({ status, articlesData }: CategoryResultsSectionProps) {
    const { t } = useTranslation('back_royal');

    return (
        <section className="relative overflow-hidden pb-20 pt-20 sm:pt-[135px]">
            <SlopeBackground />
            <Container className="relative">
                <ArticleGallery title={t('resources.resources.articles')} items={articlesData || []}>
                    {status === 'error' ? (
                        <Typography variant="body1" className="mt-[70px] text-center text-beige-beyond-beyond-dark">
                            {t('resources.resources.failed_to_load_articles')}
                        </Typography>
                    ) : null}

                    {status === 'loading' ? <FrontRoyalSpinner /> : null}

                    {status === 'success' ? (
                        <>
                            {articlesData?.length === 0 ? (
                                <Typography variant="smallText" className="mt-7.5 text-grey-darker">
                                    {parseHtml(t('resources.resources.we_have_found_articles', { count: 0 }))}
                                </Typography>
                            ) : null}

                            <Typography variant="body1" className="mt-[70px] text-center text-beige-beyond-beyond-dark">
                                {t('resources.resources.can_t_find_what_you_re_looking_for')}{' '}
                                <AnchorLink
                                    to="."
                                    state={{ openSearch: true, timestamp: new Date().toISOString() }}
                                    color="brand"
                                    replace
                                    target="_self"
                                >
                                    {parseHtml(t('resources.resources.search_here'))}
                                </AnchorLink>
                            </Typography>
                        </>
                    ) : null}
                </ArticleGallery>
            </Container>
        </section>
    );
}
